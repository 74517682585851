import { useIdleTimer } from "react-idle-timer";
import { signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { isProd } from "../src/helpers";

interface Props {
  children: React.ReactElement;
}

const IdleCheck = ({ children }: Props) => {
  const [remaining, setRemaining] = useState(0);

  const data = useSession();

  const onIdle = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error(error);
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 90_000_0,
    throttle: 500,
    disabled: data.status !== "authenticated",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  if (remaining <= 60) {
    console.info(`Session will expire in ${remaining} seconds`);
  }

  return children;
};

export default IdleCheck;
